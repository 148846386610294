import { hasMatchingAttributes } from './hasMatchingAttributes';
import { ConfigurableVariant } from '@/types/product';

export const productHasDiscount = (productVariants: ConfigurableVariant[], attributeValueIndex: number) =>
    productVariants.some(({ attributes, product }) => {
        const isValidVariant = hasMatchingAttributes(attributes, attributeValueIndex);

        return (
            isValidVariant &&
            product.price_range.minimum_price.discount.amount_off &&
            product.price_range.minimum_price.final_price.value
        );
    });
