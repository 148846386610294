import { findMatchingVariant } from './findMatchingVariant';
import { isProductConfigurableAndHasOptions } from './isProductConfigurableAndHasOptions';
import type { TProductPrice, SwatchOptions } from '@/types/product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';
import { getPriceFromRange } from '@/components/RootComponents/Category/modules/ProductCard/hooks/useProductCard';

export const getConfigPrice = (
    product: TParsedProductForContext,
    optionCodes: SwatchOptions['optionCodes'],
    optionSelections: SwatchOptions['optionSelections'],
    defaultCurrency?: string | null,
): TProductPrice => {
    const isConfigurable = isProductConfigurableAndHasOptions(product);

    if (isConfigurable && (!optionCodes || !optionSelections)) {
        return {
            currency: defaultCurrency || '',
            hasDiscount: false,
            maxPrice: 0,
            minPrice: 0,
            value: 0,
        };
    }

    const optionsSelected = [...optionSelections.values()].filter(Boolean).length;

    const item =
        isConfigurable &&
        optionsSelected &&
        findMatchingVariant({
            optionCodes,
            optionSelections,
            variants: product?.variants,
        });

    const { price_range } = item?.product || product || {};

    //The second parameter of the getPriceFromRange function is in this case a formal boolean, since we always want to see !!discount?.amount_off for this processing location
    return getPriceFromRange(price_range, 1, defaultCurrency);
};
